<template>
  <div class="pb-32">
    <h2 class="text-xl font-bold mb-4">
      Credit Card Statistics
    </h2>

    <template>
      <div class="flex">
        <div class="w-full data-table-control mb-3 flex">
          <date-range-picker
            ref="picker"
            :locale-data="{ firstDay: 1, format: dateRange.format }"
            :singleDatePicker="dateRange.singleDatePicker"
            :autoApply="dateRange.autoApply"
            v-model="dateRange.value"
            opens="center"
            @update="updateDateRange"
            :append-to-body="true"
            @toggle="() => {}"
          />
          <button
            @click.prevent="resetStatistics"
            type="button"
            class="ml-6 btn-sm btn-blue rounded-sm px-6"
          >
            Reset
          </button>
        </div>
      </div>
    </template>

    <template v-if="loading">
      <loader />
    </template>

    <template v-else>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4" style="margin-top: 1px; margin-left: 1px">
        <template v-for="(stat, n) in stats.data">
          <div class="col-span-1 p-6 border" style="margin-left: -1px; margin-top: -1px;" :key="n">
            <div class="text-sm font-thin text-gray-900 mb-3 flex">
              <span class="mr-2">{{ stat.key | fromSlug | sentenceCase }}</span>
              <tool-tip :message="mapData(stat.key)">                
              <!-- <tool-tip message="hello">                 -->
                <ion-icon name="information-circle-outline" class="cursor-pointer text-xl"></ion-icon>
              </tool-tip>
            </div>
            <div class="text-sm font-bold" v-if="stat.type == 'amount'">₦ {{ stat.value | currency }}</div>
            <div class="text-sm font-bold" v-else-if="stat.type == 'percentage'">{{ stat.value.toFixed(2) }} %</div>
            <div class="text-sm font-bold" v-else-if="stat.type == 'amount_percentage'"><span class="text-base">₦ {{ stat.value | currency }}</span> ({{ stat.percentage.toFixed(2) }} %)</div>
            <div class="text-sm font-bold" v-else-if="stat.type == 'count_percentage'"><span class="text-base">{{ stat.value }}</span> ({{ stat.percentage.toFixed(2) }} %)</div>
            <div class="text-sm font-bold" v-else>{{ stat.value }}</div>
          </div>
        </template>
      </div> 
    </template>
  </div>
</template>

<script>
export default {
  name: "RepaymentStatistics",
  components: {
    dateRangePicker: require("vue2-daterange-picker").default,
  },
  data() {
    return {
      stats: this.$options.resource([]),
      loading: false,
      dateRange: {
        value: {
          startDate: null,
          endDate: null,
        },
        format: "dd/mm/yyyy",
      },
      dataDetails: {
        "total_account":
          "Total number of accounts(users with credit card limit)",
        total_active_account:
          "Users that made transactions on their card within the specified date range period",
        total_inactive_account:
          "Users that didn't make any transactions on their card within the specified date range period",
        total_activated: "Users with approved account but without any transactions",
        total_credit_limit:
          "Total credit limit give to all accounts on the system",
        total_disbursed:
          "Total amount of money disbursed out to users on credit card",
        total_credit_card_payment:
          "Total repayment paid by users for credit cards",
        total_undue:
          "Total amount of money spent by users in their current cycle",
        total_due:
          "Total amount of money spent by users who are due to clear their balance",
        total_defaulting:
          "Total amount of money spent by users whose grace period is over",
        total_debit_transaction: "Total debit transactions on the system within the specified range",        
        total_card_utilization_rate:
          "Total card utilization rates(How well the users make use of their cards)",
        interest_paid: "Total interest paid by users on credit cards",
        annual_plan_fee: "Total annual Plan fee earned",
        total_fees_and_charges: "Total fees and charges earned",
        total_deferred_plan_amount: "Total deferred Plan earned"
      }
    };
  },
  beforeMount() {
    this.getCreditCardStatistics();
  },
  computed: {
    query() {
      let rangeStart = this.dateRange.value.startDate;
      if (rangeStart) {
        let date = new Date(rangeStart);
        rangeStart = `${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`;
      }
      let rangeEnd = this.dateRange.value.endDate;
      if (rangeEnd) {
        let date = new Date(rangeEnd);
        rangeEnd = `${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`;
      }

      const obj = {
        rangeEnd,
        rangeStart,
      };

      var query_string = Object.keys(obj)
        .map((key) => {
          if (obj[key]) {
            return `${key}=${obj[key]}`;
          }
        })
        .filter((key) => !!key)
        .join("&");

      return query_string;
    },
  },
  methods: {
    resetStatistics() {
      this.dateRange.value = { startDate: null, endDate: null };
      this.getCreditCardStatistics();
    },
    summaryData(key, default_value = 0) {
      const data = this.stats?.[key];
      return data ? data : default_value;
    },
    updateDateRange() {
      this.getCreditCardStatistics();
    },
    mapData(key) {
      const value = this.dataDetails[key];
      return value ?? "";
    },
    async getCreditCardStatistics() {
      let url = `${this.$baseurl}/admin/personal/cards/credit-card-summary`;
      if (this.query) {
        url = `${this.$baseurl}/admin/personal/cards/credit-card-summary?${this.query}`;
      }
      this.loading = true;
      await this.$get({
        url: url,
        headers: this.headers,
        success: (response) => {
          this.stats.data = response.data.summary;
        },
        error: (error) => {
          console.log("err", error);
        },
      });

      this.loading = false;
    },
  },
};
</script>
